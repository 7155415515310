import React, { useMemo, useState } from 'react';
import { Button, Col, Form, Row } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './NotificationsSettings.scss';

const NotificationsSettings = () => {
    const [emailNotifications, setEmailNotifications] = useState(false);
    const [pushNotifications, setPushNotifications] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Handle form submission
    }

    const notificationsSettings = useMemo(() => {
        return (
            <Form>
                <Form.Group as={Row} className="switch-wrapper" controlId="emailNotifications">
                    <Form.Label column sm={8}>Email Notifications</Form.Label>
                    <Col sm={4}>
                        <div className="custom-switch">
                            <Form.Check
                                type="switch"
                                checked={emailNotifications}
                                onChange={() => setEmailNotifications(!emailNotifications)}
                            />
                            <span className="slider"></span>
                        </div>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="switch-wrapper" controlId="pushNotifications">
                    <Form.Label column sm={8}>Push Notifications</Form.Label>
                    <Col sm={4}>
                        <div className="custom-switch">
                            <Form.Check
                                type="switch"
                                checked={pushNotifications}
                                onChange={() => setPushNotifications(!pushNotifications)}
                            />
                            <span className="slider"></span>
                        </div>
                    </Col>
                </Form.Group>
            </Form>
        );
    }, [emailNotifications, pushNotifications]);

    return (
        <Row className='mt-4 notifications-settings'>
            <Col xs={12} md={6} className='mx-auto'>
                {notificationsSettings}
                <Button
                    style={{ padding: '1rem 4rem' }}
                    variant='primary'
                    className='w-auto mt-5 d-block'
                    onClick={(event) => { (async () => await handleSubmit(event))() }}
                >
                    Save
                </Button>
            </Col>
        </Row>
    );
};

export default NotificationsSettings;
